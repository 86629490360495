import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import StyleCommandRepositoryInterface from '@/services/MapEditor/domain/repository/StyleCommandRepositoryInterface'
import StyleCreateCommand from '@/services/MapEditor/application/command/StyleCreateCommand'
import { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'

export default class StyleCreateHandler implements CommandHandlerInterface
{
    private repo: StyleCommandRepositoryInterface;

    constructor(repo: StyleCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: StyleCreateCommand): Promise<void>
    {
        let item: StyleDTO = {
            name: command.getName(),
            description: command.getDescription(),
            type: command.getType(),
            properties: command.getProperties(),
            project_id: command.getProjectId()
        };
        return this.repo.insert(item);
    }
}